import { Component, Input, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PortfolioEvidenceTypeString } from '@core/enums/portfolio';
import { IEvidenceDetails } from '@core/interfaces/iclass-portfolio';
import { addHTTPS } from '@core/services/helper.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'drdp-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {
  @Input() items: IEvidenceDetails[] = [];
  @Input() sasToken?: any;
  @Input() evidenceType: string = '';
  showVideo: boolean = false;

  public get evidenceTypes() { return PortfolioEvidenceTypeString; }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    center: true,
    items: 1
  }
  
  constructor(private sanitizer: DomSanitizer) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sasToken']) 
      this.items.forEach((item: IEvidenceDetails) => item.generatedUrl = this.generateUrlWithSasToken(item.url));
  }

  generateUrlWithSasToken(blobUrl?: string): SafeResourceUrl | string {
    if (this.sasToken) {
      const urlWithSasToken = `${addHTTPS(blobUrl)}${this.sasToken.value}`;
      return this.sanitizer.bypassSecurityTrustResourceUrl(urlWithSasToken);
    }
    return '';
  }
}

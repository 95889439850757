<div class="max-w-sm relative">
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let item of items">
      <ng-template carouselSlide>
        <div class="pl-8 pr-6">
          <div *ngIf="item.url">
            <div *ngIf="evidenceType === evidenceTypes.Photo">
              <img [src]="item.generatedUrl" alt="Evidence image">
            </div>

            <div *ngIf="evidenceType === evidenceTypes.Video">
              <div class="mt-4 cursor-pointer" (click)="showVideo = !showVideo">
                <div>
                  <label [for]="item.fileName">Name:</label>
                  <span class="break-words">{{ item.fileName }}</span>
                </div>
                <div class="flex justify-between">
                  <div>
                    <label for="createdDate">Upload Date:</label>
                    <span>{{ item.createdOn | localDate | date: "MM/dd/yy" }}</span>
                  </div>
                  <div class="mt-1">
                    <i class="uil text-xl" [ngClass]="{'uil-angle-down': showVideo, 'uil-angle-right': !showVideo}"></i>
                  </div>
                </div>
              </div>
              <div *ngIf="showVideo" class="mt-2">
                <drdp-video [url]="item.generatedUrl"></drdp-video>
              </div>
            </div>

            <div *ngIf="evidenceType === evidenceTypes.Audio">
              <label>{{ item.fileName }}</label>
              <drdp-audio [url]="item.generatedUrl"></drdp-audio>
            </div>
          </div>

          <div *ngIf="item.notes">
            <label for="notes" class="mt-4 mb-2">Notes</label>
            <p>{{ item.notes }}</p>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>

import { ReactiveFormsModule } from '@angular/forms';
import { BtnComponent } from './components/btn/btn.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { MenuLinkComponent } from './components/menu-link/menu-link.component';
import { DropMenuComponent } from './components/drop-menu/drop-menu.component';
import { HeaderDropmenuComponent } from './components/header-dropmenu/header-dropmenu.component';
import { HeaderLogoAndImageComponent } from './components/header-logo-and-image/header-logo-and-image.component';
import { TitleComponent } from './components/title/title.component';
import { ModalComponent } from './components/modal/modal.component';
import { AddEditComponent } from './components/drdp-tables/add-edit/add-edit.component';
import { SearchComponent } from './components/search/search.component';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToastComponent } from './components/toast/toast.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NewsItemComponent } from './components/news-item/news-item.component';
import { ContainerComponent } from './components/container/container.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { SelectComponent } from './components/dropdowns/select/select.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PillComponent } from './components/pill/pill.component';
import { SelectStateComponent } from './components/dropdowns/select-state/select-state.component';
import { SelectAgencyComponent } from './components/dropdowns/select-agency/select-agency.component';
import { UploadTableComponent } from './components/drdp-tables/upload-table/upload-table.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { ClassroomRatingPeriodParamsComponent } from './components/dropdowns/classroom-rating-period-params/classroom-rating-period-params.component';
import { SelectSiteComponent } from './components/dropdowns/select-site/select-site.component';
import { FilterSelectComponent } from './components/dropdowns/filter-select/filter-select.component';
import { SelectRoleComponent } from './components/dropdowns/select-role/select-role.component';
import { SelectCountyComponent } from './components/dropdowns/select-county/select-county.component';
import { SelectGranteeComponent } from './components/dropdowns/select-grantee/select-grantee.component';
import { SelectStatusComponent } from './components/dropdowns/select-status/select-status.component';
import { SelectSuffixComponent } from './components/dropdowns/select-suffix/select-suffix.component';
import { SelectSchoolYearComponent } from './components/dropdowns/select-school-year/select-school-year.component';
import { SelectTypeComponent } from './components/dropdowns/select-type/select-type.component';
import { SelectRatingPeriodComponent } from './components/dropdowns/select-rating-period/select-rating-period.component';
import { SelectTeacherComponent } from './components/dropdowns/select-teacher/select-teacher.component';
import { SelectHeadStartComponent } from './components/dropdowns/select-head-start/select-head-start.component';
import { SelectVendorComponent } from './components/dropdowns/select-vendor/select-vendor.component';
import { SelectRegionComponent } from './components/dropdowns/select-region/select-region.component';
import { GroupSelectComponent } from './components/dropdowns/group-select/group-select.component';
import { SelectSiteCodeComponent } from './components/dropdowns/select-site-code/select-site-code.component';
import { VideoComponent } from './components/video/video.component';
import { AudioComponent } from './components/audio/audio.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
import { SelectSelpaComponent } from './components/dropdowns/select-selpa/select-selpa.component';
import { PortfolioEvidenceManagementComponent } from './components/portfolio-evidence-management/portfolio-evidence-management.component';
import { EvidencePhotosComponent } from './components/portfolio-evidence-management/evidence-photos/evidence-photos.component';
import { EvidenceVideoComponent } from './components/portfolio-evidence-management/evidence-video/evidence-video.component';
import { EvidenceAudioComponent } from './components/portfolio-evidence-management/evidence-audio/evidence-audio.component';
import { EvidenceNotesComponent } from './components/portfolio-evidence-management/evidence-notes/evidence-notes.component';
import { RatingSheetComponent } from './components/portfolio-evidence-management/rating-sheet/rating-sheet.component';
import { SelectClassroomComponent } from './components/dropdowns/select-classroom/select-classroom.component';
import { SelectAgeGradeComponent } from './components/dropdowns/select-age-grade/select-age-grade.component';
import { SelectProvidersComponent } from './components/dropdowns/select-providers/select-providers.component';
import { MatMenuModule } from '@angular/material/menu';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { SelectYesNoComponent } from './components/dropdowns/select-yes-no/select-yes-no.component';
import { SelectIepPlanComponent } from './components/dropdowns/select-iep-plan/select-iep-plan.component';
import { SelectGenderComponent } from './components/dropdowns/select-gender/select-gender.component';
import { SelectRaceComponent } from './components/dropdowns/select-race/select-race.component';
import { SelectProgramTypeComponent } from './components/dropdowns/select-program-type/select-program-type.component';
import { SelectLanguageComponent } from './components/dropdowns/select-language/select-language.component';
import { SelectPortfolioUsersComponent } from './components/dropdowns/select-portfolio-users/select-portfolio-users.component';
import { SelectRequestReasonComponent } from './components/dropdowns/select-request-reason/select-request-reason.component';
import { SelectRequestStatusTypeComponent } from './components/dropdowns/select-request-status-type/select-request-status-type.component';
import { SelectReportTypeComponent } from './components/dropdowns/select-report-type/select-report-type.component';
import { SelectReportActionComponent } from './components/dropdowns/select-report-action/select-report-action.component';
import { SelectStatePreschoolComponent } from './components/dropdowns/select-state-preschool/select-state-preschool.component';
import { SelectContractorComponent } from './components/dropdowns/select-contractor/select-contractor.component';
import { SelectGlobalRatingComponent } from './components/dropdowns/select-global-rating/select-global-rating.component';
import { SelectClassComponent } from './components/dropdowns/select-class/select-class.component';
import { SelectOutcomeComponent } from './components/dropdowns/select-outcome/select-outcome.component';
import { SelectInitialAssessmentToolTypeComponent } from './components/dropdowns/select-initial-assessment-tool-type/select-initial-assessment-tool-type.component';
import { EvidenceStructuredPromptComponent } from './components/portfolio-evidence-management/evidence-structured-prompt/evidence-structured-prompt.component';
import { ClassPortfolioEvidenceComponent } from './components/class-portfolio-evidence/class-portfolio-evidence.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';

export const Components = [
  SideMenuComponent,
  HeaderComponent,
  BtnComponent,
  FooterComponent,
  MenuLinkComponent,
  DropMenuComponent,
  HeaderDropmenuComponent,
  HeaderLogoAndImageComponent,
  TitleComponent,
  ModalComponent,
  AddEditComponent,
  ToastComponent,
  PaginationComponent,
  SelectComponent,
  SpinnerComponent,
  SearchComponent,
  NewsItemComponent,
  ContainerComponent,
  ConfirmationModalComponent,
  PillComponent,
  SelectStateComponent,
  SelectAgencyComponent,
  UploadTableComponent,
  ClassroomRatingPeriodParamsComponent,
  SelectSiteComponent,
  FilterSelectComponent,
  SelectRoleComponent,
  SelectCountyComponent,
  SelectStatusComponent,
  SelectSuffixComponent,
  SelectGranteeComponent,
  SelectSchoolYearComponent,
  SelectTypeComponent,
  SelectRatingPeriodComponent,
  SelectTeacherComponent,
  SelectHeadStartComponent,
  SelectSiteCodeComponent,
  SelectVendorComponent,
  SelectRegionComponent,
  GroupSelectComponent,
  VideoComponent,
  AudioComponent,
  SelectSelpaComponent,
  PortfolioEvidenceManagementComponent,
  EvidencePhotosComponent,
  EvidenceVideoComponent,
  EvidenceAudioComponent,
  EvidenceNotesComponent,
  RatingSheetComponent,
  SelectClassroomComponent,
  SelectAgeGradeComponent,
  SelectProvidersComponent,
  DatePickerComponent,
  SelectYesNoComponent,
  SelectOutcomeComponent,
  SelectInitialAssessmentToolTypeComponent,
  SelectIepPlanComponent,
  SelectGenderComponent,
  SelectRaceComponent,
  SelectProgramTypeComponent,
  SelectLanguageComponent,
  SelectPortfolioUsersComponent,
  SelectRequestReasonComponent,
  SelectRequestStatusTypeComponent,
  SelectReportTypeComponent,
  SelectReportActionComponent,
  SelectStatePreschoolComponent,
  SelectContractorComponent,
  SelectGlobalRatingComponent,
  SelectClassComponent,
  EvidenceStructuredPromptComponent,
  ClassPortfolioEvidenceComponent,
  CarouselComponent
];

export const Modules = [
  ReactiveFormsModule,
  MatInputModule,
  MatSelectModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatFormFieldModule,
  MatDialogModule,
  MatButtonModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  MatSnackBarModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTooltipModule,
  MatCheckboxModule,
  MatSlideToggleModule,
  MatRadioModule,
  MatIconModule,
  MatCardModule,
  MatButtonToggleModule,
  MatTabsModule,
  MatBadgeModule,
  MatMenuModule,
  CarouselModule
];

import { Injectable } from '@angular/core';
import { IPortfolioAddEvidenceParams, 
        IPortfolioAddModel, 
        IPortfolioClassesRequest, 
        IPortfolioClassResponse, 
        IPortfolioClassStudentsRequest, 
        IPortfolioEvidenceResponse, 
        IPortfolioAssignMeasureEvidence, 
        IPortfolioUpdateEvidence, 
        IPortfolioStudentInformation, 
        IAvailableEvidence, 
        IEvidenceRequest, 
        IBatchEvidenceRequest, 
        IPrevPortfolioEvidenceResponse, 
        IBatchEvidenceResult} from '@core/interfaces/iportfolio';
import { IStudent } from '@core/interfaces/istudent';
import { IBaseTemplateDomain } from '@views/user/configuration/rating-view-setup/interfaces/ibase-template-domain';
import { IMeasureRatingLevel } from '@views/user/input-rating/interfaces/imeasure-rating-level';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { IFutureEvidence } from '@core/interfaces/ifuture-evidence';
import { IFutureEvidenceResponse } from '@core/interfaces/ifuture-evidence-response';
import { IClassDomainEvidence } from '@core/interfaces/iclass-portfolio';

@Injectable({
    providedIn: 'root'
})
export class PortfolioService {
    controllerName: string = 'portfolio';
    portfolioAddEvidenceParams: IPortfolioAddEvidenceParams | null = null;

    constructor(private dataService: DataService) { }

    setPortfolioAddEvidenceParams(addEvidenceParams: IPortfolioAddEvidenceParams) {
        this.portfolioAddEvidenceParams = addEvidenceParams;
    }

    getPortfolioAddEvidenceParams(): IPortfolioAddEvidenceParams | null {
        return this.portfolioAddEvidenceParams;
    }

    getPortfolioStudent(id: number): Observable<IStudent> {
        return this.dataService.get(`${this.controllerName}/student/${id}`);
    }

    searchClasses(payload: IPortfolioClassesRequest): Observable<any> {
        return this.dataService.post(`${this.controllerName}/classes`, payload);
    }

    getPortfolioClassStudents(payload: IPortfolioClassStudentsRequest): Observable<any> {
        return this.dataService.post(`${this.controllerName}/class-students`, payload);
    }

    getPortfolioClass(id: number, ageGradeInstrumentId: number): Observable<IPortfolioClassResponse> {
        return this.dataService.get(`${this.controllerName}/class/${id}/ageGradeInstrument/${ageGradeInstrumentId}`);
    }

    getBaseMeasures(ageGradeInstrumentId: number): Observable<IBaseTemplateDomain[]> {
        return this.dataService.get(`${this.controllerName}/base-measures/${ageGradeInstrumentId}`);
    }

    saveEvidence(portfolioAddModel: IPortfolioAddModel[]): Observable<boolean> {
        return this.dataService.post(`${this.controllerName}/save-evidence`, portfolioAddModel);
    }

    saveFutureEvidence(portfolioAddModel: IPortfolioAddModel[]): Observable<boolean> {
        return this.dataService.post(`${this.controllerName}/future-evidence`, portfolioAddModel);
    }

    getFutureEvidenceByStudent(studentId: number): Observable<IFutureEvidence[]> {
        return this.dataService.get(`${this.controllerName}/student-future-evidence/${studentId}`);
    }

    getFutureEvidencesById(futureEvidenceId: number): Observable<IFutureEvidenceResponse> {
        return this.dataService.get(`${this.controllerName}/future-evidences/${futureEvidenceId}`);
    }


    getBlobSasToken(containerName: string): Observable<string> {
        return this.dataService.get(`${this.controllerName}/blobstorage-sastoken/${containerName}`);
    }

    getRatingMeasures(ageGradeInstrumentId: number, measureId: number): Observable<IMeasureRatingLevel> {
        return this.dataService.get(`${this.controllerName}/rating-measures/ageGradeInstrument/${ageGradeInstrumentId}/measure/${measureId}`);
    }

    getDomainEvidences(studentClassEnrollmentId: number, ageGradeInstrumentId: number): Observable<IPortfolioEvidenceResponse[]> {
        return this.dataService.get(`${this.controllerName}/review-domain-evidence/studentenrollment/${studentClassEnrollmentId}/agegradeinstrument/${ageGradeInstrumentId}`);
    }

    getMeasureEvidences(studentClassEnrollmentId: number, ageGradeInstrumentId: number): Observable<IPortfolioEvidenceResponse[]> {
        return this.dataService.get(`${this.controllerName}/review-measure-evidence/studentenrollment/${studentClassEnrollmentId}/agegradeinstrument/${ageGradeInstrumentId}`);
    }

    assignEvidence(portfolioAssignModel: IPortfolioAssignMeasureEvidence, message?: string): Observable<boolean> {
        return this.dataService.post(`${this.controllerName}/assign-evidence`, portfolioAssignModel, message);
    }

    copyEvidence(evidence: IPortfolioAssignMeasureEvidence, message?: string): Observable<any> {
        return this.dataService.post(`${this.controllerName}/copy-evidence`, evidence, message);
    }

    updateEvidence(portfolioUpdateModel: IPortfolioUpdateEvidence[], message?: string): Observable<boolean> {
        return this.dataService.put(`${this.controllerName}/update-evidence`, portfolioUpdateModel, message);
    }

    deleteEvidence(measureEvidenceId: number, message?: string): Observable<boolean> {
        return this.dataService.delete(`${this.controllerName}/delete-measure-evidence/${measureEvidenceId}`, message);
    }

    generateDomainPdf(id: number, ageGradeId: number) {
        return this.dataService.postWithOptions(`${this.controllerName}/download-domain/${id}/ageGrade/${ageGradeId}`, null, {
            responseType: 'blob',
        });
    }

    generateMeasurePdf(id: number, ageGradeId: number) {
        return this.dataService.postWithOptions(`${this.controllerName}/download-measure/${id}/ageGrade/${ageGradeId}`, null, {
            responseType: 'blob',
        });
    }

    attachFutureEvidence(request: IPortfolioStudentInformation): Observable<boolean> {
      return this.dataService.post(`${this.controllerName}/attach-future-evidence`, request);
    }

    getAvailableEvidenceToReq(studentId: number, ratingPeriodId: number, toEnrollmentId: number): Observable<IAvailableEvidence> {
        return this.dataService.get(`${this.controllerName}/available-evidence/student/${studentId}/rating/${ratingPeriodId}/toEnrollment/${toEnrollmentId}`);
    }

    processEvidenceRequest(request: IEvidenceRequest, message?: string): Observable<boolean> {
        return this.dataService.post(`${this.controllerName}/evidence-request`, request, message);
    }

    updateEvidenceRequests(request: IBatchEvidenceRequest, message?: string): Observable<IBatchEvidenceResult> {
        return this.dataService.put(`${this.controllerName}/update-evidence-request`, request, message);
    }

    getClassPortfolio(classId: number): Observable<IClassDomainEvidence[]> {
        return this.dataService.get(`${this.controllerName}/class-portfolio/${classId}`);
    }
}

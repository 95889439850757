import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { IClassDomainEvidence } from "@core/interfaces/iclass-portfolio";
import { PortfolioService } from "@core/services/portfolio.service";
import { ToastService } from "@core/services/toast.service";
import { ClassPortfolioEvidenceComponent } from "@shared/components/class-portfolio-evidence/class-portfolio-evidence.component";
import { take } from "rxjs";


@Component({
  selector: 'drdp-class-based-portfolio',
  templateUrl: './class-based-portfolio.component.html',
  styleUrls: ['./class-based-portfolio.component.scss']
})
export class ClassBasedPortfolioComponent implements OnInit {
  classId: number = 0;
  classPortfolio: IClassDomainEvidence[] = [];
  showDomain: boolean = false;
  selectedDomain: string = '';
  showMeasure: boolean = false;
  selectedMeasure: string = '';
  searchValues: any | null = {};

  constructor(
    private route: ActivatedRoute,
    private portfolioSerive: PortfolioService,
    private router: Router,
    private toastService: ToastService,
    public dialog: MatDialog
  ) { 
    this.searchValues = this.router.getCurrentNavigation()?.extras?.state?.['searchValues'] || null; 
    if (!this.searchValues) {
      this.toastService.error('Choose a classroom from table results.');
      this.router.navigate(['/portfolio']);
    }
  }

  ngOnInit(): void {
    const classId = this.route.snapshot.paramMap.get('classId');
    if (!classId) return;

    this.classId = +classId;
    this.getClassEvidence();
  }

  getClassEvidence(): void {
    this.portfolioSerive.getClassPortfolio(this.classId).pipe(
      take(1)
    ).subscribe((data: IClassDomainEvidence[]) => {
      this.classPortfolio = data;
    });
  }

  changeDomainShow(domainCode: string): void {
    this.showDomain = !this.showDomain;
    this.selectedDomain = domainCode;
  }

  changeMeasureShow(measureCode: string): void {
    this.showMeasure = !this.showMeasure;
    this.selectedMeasure = measureCode;
  }

  navigateToClassList(): void {
    this.router.navigate([
      `/portfolio/class/${this.classId}/ageGradeInstrument/${this.searchValues.ageGradeInstrumentId}`,
    ]);
  }

  handleReviewEvidence(event: any): void { 
    if (!event || !event.data) return;

    if (event.type === 'review') {
      this.router.navigate([
        `portfolio/review-evidence/class/${this.classId}/student-enrollment/${event.data.enrollmentId}/age-grade-instrument/${event.data.ageGradeInstrumentId}`,
      ], {
        queryParams: {
          portfolioId: event.data.portfolioId
        }
      });
    }
    else {
      this.dialog.open(ClassPortfolioEvidenceComponent, { 
        data: event, 
        panelClass: 'portfolio-dialog-container' 
      });
    }
  }
}

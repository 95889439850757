import { TableColumnType } from '@core/enums/table';
import { formatDate } from '@angular/common';

export function formatDatesToString(date: any, dateFormat?: string): string {
  if (!date) return '';
  return formatDate(date, dateFormat ? dateFormat : 'MM/dd/yyyy', 'en');
}

export function formatToDate(date: any): string | null {
  if (!date) return null;
  return new Date(date).toLocaleDateString('sv');
}

export function formatStringsToNumberArr(value: string): Number[] {
  if (value === '') return [];
  return value?.split(', ').map(Number);
}

export function formatStringToStringArr(value: string | string[]): string[] {
  if (Array.isArray(value) && value.every((item) => typeof item === 'string')) {
    return value;
  } else if (typeof value === 'string') {
    return value.split(', ');
  } else {
    return [];
  }
}

export function setIconDisplayObject(tableColumns: any[], data: any): Object {
  var displayIcons: Record<string, any> = {};
  tableColumns.forEach((tableColumn: any) => {
    var key = tableColumn.columnDef;
    if (tableColumn.type == TableColumnType.icon || tableColumn.type == TableColumnType.infoIcon) {
      displayIcons[key] = data[key] == true || data[key] == null;
    }
  });

  return displayIcons;
}

export function getSearchUrl(baseUrl: string, params: any): string {
  // Build search params only if value exists
  return baseUrl.concat(
    Object.entries(params)
      .map(([key, val]) => {
        if (val) {
          return `${key}=${val}`;
        }
        return;
      })
      .filter(Boolean)
      .join('&')
  );
}

export function mapClassResToDRDPSelect(
  res: any,
  getClassroomId: boolean = false,
  showPilot: boolean = false
): any {
  if (!res) return null;
  return res.map((data: any) => ({
    id: getClassroomId ? data.id : data.classId,
    classroomName: data.classroomName ?? data.name,
    siteId: data.siteId,
    ...(showPilot && { isPilot: data.isPilot ?? null })
  }));
}

export function transformStringToNumArray(str: string): number[] {
  if (str && str.length > 1) return str.split(',').map(Number);
  else if (str && str.length == 1) return [+str];
  else return [];
}

export function isNumber(charCode: number): boolean {
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

export function isDateBetween(startDate: Date, endDate: Date, checkDate: Date) {
  return checkDate >= startDate && checkDate <= endDate;
}

export function trimHTTPS(url: string | any) {
  if (url.startsWith('http://')) {
    return url.slice(7);
  } else if (url.startsWith('https://')) {
    return url.slice(8);
  }
  return url;
}

export function addHTTPS(url: string | any) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return 'https://' + url;
  }
  return url;
}

export function replaceWhitespace(value: string) {
  return (value.replace(/[\s\t\n\r]+/g, ' ')).trim();
}

export function cleanFileName(fileName: string): string {
  // Regular expression to match ISO 8601 date format at the end of the string
  const dateRegex = /-\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
  
  // Remove the date if it exists
  return fileName.replace(dateRegex, '');
}
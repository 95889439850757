import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PortfolioEvidenceType } from '@core/enums/portfolio';
import { IStudentEvidence } from '@core/interfaces/iclass-portfolio';
import { setIconDisplayObject } from '@core/services/helper.service';

@Component({
  selector: 'drdp-student-evidence',
  templateUrl: './student-evidence.component.html',
  styleUrls: ['./student-evidence.component.scss']
})
export class StudentEvidenceComponent implements OnInit {
  @Input() students: IStudentEvidence[] = [];
  @Output() student = new EventEmitter();

  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  infoIconColumnConfig = {
    type: 'infoIcon',
    colorClass: 'text-drdpblue-300',
    cursorClass: 'cursor-pointer'
  };

  tableColumns = [
    { columnDef: 'firstName', header: 'First Name', type: 'text' },
    { columnDef: 'lastName', header: 'Last Name', type: 'text' },
    { columnDef: 'total', header: 'Total Items', type: 'text' },
    { columnDef: 'review', header: 'Review Evidence Page', type: 'icon', icon: 'notes', colorClass: 'text-drdpblue-300', cursorClass: 'cursor-pointer' },
    { columnDef: 'photo', header: 'Photo Evidence', icon: 'images', ...this.infoIconColumnConfig },
    { columnDef: 'video', header: 'Video Evidence', icon: 'video', ...this.infoIconColumnConfig },
    { columnDef: 'audio', header: 'Audio Evidence', icon: 'volume', ...this.infoIconColumnConfig },
    { columnDef: 'note', header: 'Note Evidence', icon: 'notes', ...this.infoIconColumnConfig },
  ];

  ngOnInit(): void {
    this.initializeTableData();
  }

  initializeTableData(): void {
    if(this.students && this.students.length) {
      this.students = this.students.map(student => ({
        ...student,
        total: student.evidences?.length || 0,
        photo: student.evidences?.filter(e => e.evidenceTypeId === PortfolioEvidenceType.Photo).length || 0,
        audio: student.evidences?.filter(e => e.evidenceTypeId === PortfolioEvidenceType.Audio).length || 0,
        video: student.evidences?.filter(e => e.evidenceTypeId === PortfolioEvidenceType.Video).length || 0,
        note: student.evidences?.filter(e => e.evidenceTypeId === PortfolioEvidenceType.Note).length || 0,
        displayIcons: setIconDisplayObject(this.tableColumns, student)
      }));
      this.dataSource = new MatTableDataSource(this.students);
    }
  }

  onReviewEvidence(event: any): void {
    this.student.emit(event);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PortfolioEvidenceType, PortfolioEvidenceTypeString } from '@core/enums/portfolio';
import { IEvidenceDetails } from '@core/interfaces/iclass-portfolio';
import { PortfolioService } from '@core/services/portfolio.service';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-class-portfolio-evidence',
  templateUrl: './class-portfolio-evidence.component.html',
  styleUrls: ['./class-portfolio-evidence.component.scss']
})
export class ClassPortfolioEvidenceComponent implements OnInit {
  evidences: IEvidenceDetails[] = [];
  studentName: string = '';
  containerName: string = 'portfolio-evidences';
  sasToken?: any;
  evidenceType: string = '';

  evidenceTypeMap = {
    [PortfolioEvidenceTypeString.Note]: PortfolioEvidenceType.Note,
    [PortfolioEvidenceTypeString.Photo]: PortfolioEvidenceType.Photo,
    [PortfolioEvidenceTypeString.Video]: PortfolioEvidenceType.Video,
    [PortfolioEvidenceTypeString.Audio]: PortfolioEvidenceType.Audio,
  };

  constructor(
    private portfolioService: PortfolioService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }

  ngOnInit(): void {
    this.initializeEvidences();
    this.getSasToken();
  }

  initializeEvidences(): void {
    if (!this.dialogData || !this.dialogData.data) return;
    
    const data = this.dialogData.data;
    this.evidenceType = this.dialogData.type;
    this.studentName = `${data.firstName} ${data.lastName}`;

    const evidenceTypeId = this.evidenceTypeMap[this.evidenceType as PortfolioEvidenceTypeString];
    
    if (evidenceTypeId)
      this.evidences = data.evidences.filter((evidence: IEvidenceDetails) => evidence.evidenceTypeId === evidenceTypeId);
  }

  getSasToken(): void {
    if (this.containerName) {
      this.portfolioService.getBlobSasToken(this.containerName)
        .pipe(take(1)).subscribe((res: string) => this.sasToken = res);
    } 
  }
}

<h2 class="mb-2 mt-0 text-3xl ">
  Viewing Current Evidence & Totals for: <b>{{ searchValues?.classroomName }}</b>
</h2>
<h2 class="mb-2 mt-0 text-2xl">
  Rating Period: <b>{{ searchValues?.ratingPeriodName }}</b>
</h2>
<div class="flex justify-end items-center mr-4 mb-4">
  <a class="text-sm cursor-pointer" (click)="navigateToClassList()">
    Go To Class List
    <i class="uil uil-angle-right-b text-base"></i>
  </a>
</div>
<drdp-container *ngIf="classPortfolio.length; else noResults">
  <h2 class="mt-1 mb-3 text-xl font-bold">Evidence Totals</h2>
  <div *ngFor="let domain of classPortfolio" class="mb-2">
    <div class="md:flex justify-between mt-4">
      <div class="font-semibold text-xl flex items-center gap-3">
        <img class="h-5" src="assets/images/domains/{{domain.iconName}}" [alt]="domain.domainCode">
        {{ domain.domainCode }}
        <i class="uil uil-minus text-2xl -mx-3"></i>
        {{ domain.domainName }}
      </div>
      <div class="flex justify-end items-center text-xl cursor-pointer" (click)="changeDomainShow(domain.domainCode)">
        <b>{{ domain.evidenceCount }} Evidence Item{{ domain.evidenceCount === 1 ? '' : 's' }}</b>
        <i class="text-2xl ml-2" [ngClass]="{'uil uil-angle-down': !showDomain || selectedDomain !== domain.domainCode,
            'uil uil-angle-right': showDomain && selectedDomain === domain.domainCode}"></i>
      </div>
    </div>
    <div *ngIf="showDomain && selectedDomain === domain.domainCode">
      <div *ngIf="domain.students.length" class="pl-4">
        <drdp-student-evidence [students]="domain.students"
          (student)="handleReviewEvidence($event)"></drdp-student-evidence>
      </div>
      <div class="pl-4" [class.mt-6]="!domain.evidenceCount">
        <div *ngFor="let measure of domain.measures">
          <div class="md:flex justify-between py-3 border-t border-black">
            <div class="flex items-center gap-3">
              {{ measure.measureCode }} - {{ measure.measureName }}
            </div>
            <div class="flex justify-self-end items-center cursor-pointer" (click)="changeMeasureShow(measure.measureCode)">
              <b>{{ measure.evidenceCount }} Evidence Item{{ measure.evidenceCount === 1 ? '' : 's' }}</b>
              <i class="text-2xl" [ngClass]="{'uil uil-angle-down': !showMeasure || selectedMeasure !== measure.measureCode,
                  'uil uil-angle-right': showMeasure && selectedMeasure === measure.measureCode}"></i>
            </div>
          </div>
          <div *ngIf="showMeasure && selectedMeasure === measure.measureCode" class="pl-4">
            <drdp-student-evidence [students]="measure.students"
              (student)="handleReviewEvidence($event)"></drdp-student-evidence>
          </div>
        </div>
      </div>
    </div>
  </div>
</drdp-container>
<ng-template #noResults><drdp-container>No portfolio available.</drdp-container></ng-template>

<div class="rounded-lg">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold">{{ label }}</h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>

  <div id="classroomAssignment" mat-dialog-content>
    <form class="grid grid-cols-2 gap-x-10 p-3" [formGroup]="classroomForm">
      <div *ngIf="hasAccessToAgencies" class="col-span-1 items-center w-[100%]">
        <drdp-select-state [initialStateId]="selectedState"
                           (state)="handleDropdown($event, selectType.States)"
                           [required]="true"
                           [isValid]="!stateId.touched || stateId.valid">
        </drdp-select-state>
      </div>

      <div *ngIf="hasAccessToAgencies" class="col-span-1 items-center w-[100%]">
        <drdp-select-agency [initialAgencyId]="selectedAgency"
                            (agency)="handleDropdown($event, selectType.Agencies)"
                            [stateId]="selectedState"
                            [required]="true"
                            [isValid]="!agencyId.touched || agencyId.valid"
                            [clear]="clearDropdown"
                            >
        </drdp-select-agency>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <drdp-filter-select label="Classroom" [options]="options" [selectType]="select.Class"
          (onSelected)="handleDropdown($event, select.Class)" [multiSelect]="false"
          [required]="true" [initialValues]="selectedClassrooomId" [showPilot]="true">
        </drdp-filter-select>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <drdp-select-teacher (teacherOfRecord)="handleDropdown($event, role.TeacherOfRecord)"
                             [initialTeacherId]="teacherOfRecordId.value"
                             [agencyId]="agencyId.value"
                             [requireSite]="true"
                             [siteId]="siteId"
                             [clear]="clearDropdown"
                             [required]="true"
                             [isValid]="!teacherOfRecordId.touched || teacherOfRecordId.valid"
                             [allTeachers]="false"
                             [teacherType]="role.TeacherOfRecord">
        </drdp-select-teacher>
      </div>


      <div class="col-span-1 items-center w-[100%]">
        <drdp-select-rating-period (ratingPeriod)="handleDropdown($event, selectType.RatingPeriods)"
                                   [initialRatingPeriodId]="ratingPeriodId.value"
                                   [agencyId]="agencyId.value"
                                   [required]="true"
                                   [disabled]="!canEditRatingPeriods"
                                   [isValid]="!ratingPeriodId.touched || ratingPeriodId.valid"
                                   [displayFutureRps]="true"
                                   [clear]="clearDropdown"
                                   [showPilotRps]="isPilotClassroom">
        </drdp-select-rating-period>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <drdp-select-teacher (teacher)="handleDropdown($event, role.Teacher)"
                             [initialTeachersId]="teachers.value"
                             [agencyId]="agencyId.value"
                             [siteId]="siteId"
                             [multiSelect]="true"
                             [multiSelectInitVals]="true"
                             [required]="false"
                             [requireSite]="true"
                             [clear]="clearDropdown"
                             [allTeachers]="false"
                             [teacherType]="role.Teacher">
        </drdp-select-teacher>
      </div>
    </form>

  </div>
  <div mat-dialog-actions class="flex justify-end align-bottom">
    <drdp-btn color="cancel" class="mr-2" mat-button mat-dialog-close>Cancel</drdp-btn>
    <drdp-btn type="submit" mat-button [disabled]="classroomForm.invalid" cdkFocusInitial (click)="onSubmit()">Save</drdp-btn>
  </div>
</div>
